.modal-simple-mfa {
  background-color: #fff; /* Light background for modal */
  border-radius: 10px;
  color: #000; /* Black text color for contrast */
}

.modal-simple-mfa  h2, .modal-simple-mfa  h3 {
  color: #333; /* Darker gray for headers */
  font-size: 20px;
  font-weight: 600;
}

.modal-simple-mfa  p.info-text {
  color: #666; /* Medium gray for informational text */
  margin-bottom: 15px;
}

.modal-simple-mfa  .step {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.modal-simple-mfa .step-number {
  background-color: #007bff; /* Use blue for step numbers */
  color: white;
  border-radius: 50%;
  padding: 10px;
  display: grid;
  font-size: 16px;
  font-weight: 600;
  margin-right: 15px;
  width: 30px;
  height: 30px;
  place-content: center;
}

.modal-simple-mfa .step-content {
  max-width: 90%;
}

.modal-simple-mfa .qr-box {
  margin: 15px 0;
  padding: 10px;
  background-color: #f1f3f5; /* Light background for QR box */
  border: 2px dashed #999; /* Lighter border */
  text-align: center;
  border-radius: 8px;
}

.modal-simple-mfa .otp-input-width {
  width: 50px; /* Uniform width */
  height: 50px;
  text-align: center;
  font-size: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 2px solid #ccc; /* Lighter border for OTP inputs */
}

.modal-simple-mfa .btn-close {
  color: #000; /* Black close button */
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.modal-simple-mfa  button {
  margin-top: 20px;
}

.modal-simple-mfa  button.rounded-pill:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
